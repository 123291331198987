//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-824:_5724,_1160,_1628,_3208,_5656,_2936,_1336,_2216;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-824')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-824', "_5724,_1160,_1628,_3208,_5656,_2936,_1336,_2216");
        }
      }catch (ex) {
        console.error(ex);
      }
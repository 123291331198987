
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function hierarchicalContainerRT () {
    function repeatHierarchicalGroups1(hierarchicalGroups, hierarchicalGroupsIndex) {
        return [hierarchicalGroups(function () {
                return _createElement('div', { 'className': 'cm_vehicle-categories__topline-wrapper' }, _createElement('a', {
                    'href': !this.subvalues.length ? `${ this.href }` : `#nav_${ this.term }`,
                    'className': 'cm_vehicle-categories__topline-link',
                    'title': this.value
                }, _createElement('img', {
                    'alt': this.value,
                    'className': 'cm_vehicle-categories__topline-image cm_vehicle-categories_category-image',
                    'src': this.getImageOrDefault(this.imageUrl)
                }), _createElement('div', { 'className': 'cm_vehicle-categories__topline-title' }, this.value)));
            }, { count: undefined })];
    }
    function repeatHierarchicalGroups2(hierarchicalGroups, hierarchicalGroupsIndex) {
        return [hierarchicalGroups(function () {
                function repeatSubvalues1(subvalues, subvaluesIndex) {
                    return [subvalues(function () {
                            return _createElement('div', { 'className': 'js-pagination-result' }, _createElement('div', { 'className': 'card hover:grow-media relative flex flex-col card__collection' }, _createElement('div', { 'className': 'card__media relative' }, _createElement('a', {
                                'href': this.href,
                                'aria-label': this.value,
                                'className': 'media block relative',
                                'tabIndex': '-1'
                            }, _createElement('img', {
                                'src': this.getImageOrDefault(this.imageUrl),
                                'className': 'img-fit no-js-hidden',
                                'loading': 'lazy',
                                'alt': this.value
                            }))), _createElement('div', { 'className': 'card__info relative text-center' }, _createElement('p', { 'className': 'card__title h6 mb-2 last:mb-0' }, _createElement('a', {
                                'href': this.href,
                                'className': 'card-link text-current'
                            }, this.value)), _createElement('a', {
                                'href': this.href,
                                'className': 'text-theme-text inline-flex card__collection--arrow'
                            }, '\n        View collection\n        ', _createElement('span', { 'className': 'has-ltr-icon flex items-center mis-2' }, _createElement('svg', {
                                'width': '18',
                                'height': '19',
                                'viewBox': '0 0 17.99 18.62',
                                'aria-hidden': 'true',
                                'focusable': 'false',
                                'role': 'presentation',
                                'className': 'icon'
                            }, _createElement('path', {
                                'd': 'M4.77 9.31h7.81M8.86 5.17l4 4.14-4 4.14',
                                'fill': 'none',
                                'stroke': 'currentColor',
                                'strokeWidth': '1.25'
                            }), _createElement('ellipse', {
                                'cx': '9',
                                'cy': '9.31',
                                'rx': '8.37',
                                'ry': '8.69',
                                'fill': 'none',
                                'stroke': 'currentColor',
                                'strokeWidth': '1.25'
                            })))))));
                        }, { count: undefined })];
                }
                return _createElement('div', { 'className': 'cm_vehicle-categories_category-wrapper' }, !this.subvalues.length && !this.isMultiLevel ? [_createElement('div', {
                        'className': 'js-pagination-result',
                        'key': '560'
                    }, _createElement('div', { 'className': 'card hover:grow-media relative flex flex-col card__collection' }, _createElement('div', { 'className': 'card__media relative' }, _createElement('a', {
                        'href': this.href,
                        'aria-label': this.value,
                        'className': 'media block relative',
                        'tabIndex': '-1'
                    }, _createElement('img', {
                        'src': this.getImageOrDefault(this.imageUrl),
                        'className': 'img-fit no-js-hidden',
                        'loading': 'lazy',
                        'alt': this.value
                    }))), _createElement('div', { 'className': 'card__info relative text-center' }, _createElement('p', { 'className': 'card__title h6 mb-2 last:mb-0' }, _createElement('a', {
                        'href': this.href,
                        'className': 'card-link text-current'
                    }, this.value)), _createElement('a', {
                        'href': this.href,
                        'className': 'text-theme-text inline-flex card__collection--arrow'
                    }, '\n        View collection\n        ', _createElement('span', { 'className': 'has-ltr-icon flex items-center mis-2' }, _createElement('svg', {
                        'width': '18',
                        'height': '19',
                        'viewBox': '0 0 17.99 18.62',
                        'aria-hidden': 'true',
                        'focusable': 'false',
                        'role': 'presentation',
                        'className': 'icon'
                    }, _createElement('path', {
                        'd': 'M4.77 9.31h7.81M8.86 5.17l4 4.14-4 4.14',
                        'fill': 'none',
                        'stroke': 'currentColor',
                        'strokeWidth': '1.25'
                    }), _createElement('ellipse', {
                        'cx': '9',
                        'cy': '9.31',
                        'rx': '8.37',
                        'ry': '8.69',
                        'fill': 'none',
                        'stroke': 'currentColor',
                        'strokeWidth': '1.25'
                    })))))))] : null, this.subvalues.length ? [
                    _createElement('h1', {
                        'className': 'cm_vehicle-categories_category-wrapper-title',
                        'id': 'nav_' + this.term,
                        'key': '13371'
                    }, _createElement('a', {
                        'className': 'cm_vehicle-categories_category-wrapper-title-link cm_vehicle-categories_link',
                        'href': this.href
                    }, '\n        ', this.value, '\n      ')),
                    [this.vehicleWidget(function () {
                            return _createElement('div', { 'className': 'cm_vehicle-widget__vehicle-category-wrapper cm_vehicleWidget' }, this.template === 'locked' ? _createElement('div', {
                                'className': 'cmTemplate_locked',
                                'key': '81'
                            }, this.vehicleString) : null);
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })],
                    _createElement.apply(this, [
                        'div',
                        {
                            'className': 'cm_vehicle-categories_category-repeater  cmRepeater_subvalues',
                            'key': '13375'
                        },
                        _map(this.subvalues, repeatSubvalues1.bind(this))
                    ])
                ] : null);
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_vehicle-categories' + (!this.isMultiLevel ? ' cm_vehicle-categories__flat ' : ' ') }, [this.vehicleWidget(function () {
            function repeatSelects1(selects, selectsIndex) {
                return [selects(function () {
                        function repeatEntry1(entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n        ', entry.value, '\n      ');
                        }
                        return _createElement('div', { 'className': this.extraClassName }, _createElement('div', { 'className': 'cm_select_toggle cm_select_toggle__native cm_toggle-dropdown' }, this.index || this.index === 0 ? _createElement('span', {
                            'className': 'index' + (this.disabled ? ' disabled' : ''),
                            'key': '117'
                        }, '\n      ', this.index + 1, '\n    ') : null, _createElement.apply(this, [
                            'select',
                            {
                                'className': 'cm_select_inner-select cm_select__pretty',
                                'onChange': this.onChange,
                                'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                'disabled': this.disabled
                            },
                            !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                'value': '',
                                'key': 'null-option'
                            }, '\n        ', this.title, '\n      ') : null,
                            this.loading ? _createElement('option', {
                                'key': 'loading-option',
                                'disabled': true
                            }, '...loading...') : null,
                            _map(this.entries, repeatEntry1.bind(this))
                        ]), _createElement('svg', {
                            'width': '15',
                            'height': '10',
                            'viewBox': '0 0 15 10',
                            'fill': 'none',
                            'xmlns': 'http://www.w3.org/2000/svg'
                        }, _createElement('path', {
                            'd': 'M13 2L7.375 8L1.75 2',
                            'stroke': '#0A0E14',
                            'strokeWidth': '2.5',
                            'strokeLinecap': 'round',
                            'strokeLinejoin': 'round'
                        }))));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__vehicle-categories  cm_vehicleWidget' }, this.template === 'active' ? _createElement('div', {
                'className': 'cm_vehicle-widget_dropdowns cmTemplate_active',
                'key': '87'
            }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, window.Convermax.config?.selectYourVehicleTitle || 'Select Your Vehicle'), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
                'div',
                { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
                _map(this.selects, repeatSelects1.bind(this))
            ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go btn btn--primary',
                'onClick': this.selectVehicle,
                'disabled': !this.allowToSetVehicle
            }, '\n    GO\n  '), _createElement('div', {
                'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear btn btn--secondary',
                'onClick': this.discardVehicle,
                'disabled': !this.allowToDiscardVehicle
            }, '\n    Clear\n  ')))) : null, this.template === 'locked' ? _createElement('div', {
                'className': 'cm_vehicle-widget_label cmTemplate_locked',
                'key': '3045'
            }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, '\n  ', this.vehicleString, '\n  ', _createElement('span', { 'className': 'cm_vehicle-widget_subtitle' }, this.fitmentSearchTitle)), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                'className': 'cm_vehicle-widget_button btn btn--primary',
                'onClick': this.changeVehicle,
                'role': 'button',
                'tabIndex': '0'
            }, '\n      Change\n    '), _createElement('div', {
                'className': 'cm_vehicle-widget_button btn btn--secondary',
                'onClick': this.discardVehicle,
                'role': 'button',
                'tabIndex': '0'
            }, '\n      Clear\n    ')))) : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], this.isMultiLevel ? _createElement.apply(this, [
        'div',
        {
            'className': 'cm_vehicle-categories_category-wrapper-repeater cm_vehicle-categories__topline  cmRepeater_hierarchicalGroups',
            'key': '5151'
        },
        _map(this.hierarchicalGroups, repeatHierarchicalGroups1.bind(this))
    ]) : null, _createElement.apply(this, [
        'div',
        { 'className': 'cm_vehicle-categories_category-wrapper-repeater  cmRepeater_hierarchicalGroups' },
        _map(this.hierarchicalGroups, repeatHierarchicalGroups2.bind(this))
    ]));
}
        export const componentNames = ["cm:vehicleWidget","cm:vehicleWidget"]